import React from 'react';

const InstagramVariant1 = () => (
  <svg
    viewBox="0 0 31 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3048 3.41415C19.3912 3.41415 19.8758 3.42945 21.4892 3.50343C23.1574 3.57995 24.8754 3.96002 26.0908 5.17547C27.3178 6.40241 27.6863 8.10379 27.7629 9.77711C27.8368 11.3905 27.8521 11.8751 27.8521 15.9615C27.8521 20.0479 27.8368 20.5325 27.7629 22.1459C27.6876 23.8052 27.2986 25.5398 26.0908 26.7476C24.8639 27.9745 23.1638 28.3431 21.4892 28.4196C19.8758 28.4936 19.3912 28.5089 15.3048 28.5089C11.2184 28.5089 10.7337 28.4936 9.12037 28.4196C7.47383 28.3444 5.71633 27.9464 4.51873 26.7476C3.29818 25.527 2.92321 23.809 2.84669 22.1459C2.77272 20.5325 2.75741 20.0479 2.75741 15.9615C2.75741 11.8751 2.77272 11.3905 2.84669 9.77711C2.92194 8.12419 3.31476 6.37945 4.51873 5.17547C5.74312 3.95109 7.45088 3.57995 9.12037 3.50343C10.7337 3.42945 11.2184 3.41415 15.3048 3.41415ZM15.3048 0.656738C11.1483 0.656738 10.6266 0.674594 8.99411 0.748567C6.62824 0.856976 4.27896 1.51508 2.56865 3.22539C0.851966 4.94208 0.200237 7.28626 0.0918287 9.65085C0.0178556 11.2834 0 11.805 0 15.9615C0 20.118 0.0178556 20.6397 0.0918287 22.2722C0.200237 24.6355 0.860894 26.9912 2.56865 28.6976C4.28406 30.413 6.63207 31.0661 8.99411 31.1745C10.6266 31.2484 11.1483 31.2663 15.3048 31.2663C19.4613 31.2663 19.9829 31.2484 21.6154 31.1745C23.98 31.0661 26.3319 30.4067 28.0409 28.6976C29.7589 26.9797 30.4093 24.6368 30.5177 22.2722C30.5917 20.6397 30.6096 20.118 30.6096 15.9615C30.6096 11.805 30.5917 11.2834 30.5177 9.65085C30.4093 7.28498 29.7499 4.93442 28.0409 3.22539C26.328 1.51253 23.9737 0.8557 21.6154 0.748567C19.9829 0.674594 19.4613 0.656738 15.3048 0.656738Z"
      fill="white"
    />
    <path
      d="M15.3047 8.10278C10.9646 8.10278 7.44574 11.6216 7.44574 15.9618C7.44574 20.302 10.9646 23.8208 15.3047 23.8208C19.6449 23.8208 23.1637 20.302 23.1637 15.9618C23.1637 11.6216 19.6449 8.10278 15.3047 8.10278ZM15.3047 21.0634C12.4874 21.0634 10.2032 18.7791 10.2032 15.9618C10.2032 13.1444 12.4874 10.8602 15.3047 10.8602C18.1221 10.8602 20.4063 13.1444 20.4063 15.9618C20.4063 18.7791 18.1221 21.0634 15.3047 21.0634Z"
      fill="white"
    />
    <path
      d="M23.475 9.62798C24.4893 9.62798 25.3115 8.80572 25.3115 7.79141C25.3115 6.7771 24.4893 5.95483 23.475 5.95483C22.4607 5.95483 21.6384 6.7771 21.6384 7.79141C21.6384 8.80572 22.4607 9.62798 23.475 9.62798Z"
      fill="white"
    />
  </svg>
);

export default InstagramVariant1;
