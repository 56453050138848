import React from 'react';

const LinkedinVariant1 = () => (
  <svg
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.1537 22.1547H18.3011V16.1212C18.3011 14.6823 18.2755 12.8309 16.2972 12.8309C14.2908 12.8309 13.9833 14.3979 13.9833 16.0174V22.1547H10.132V9.74685H13.8309V11.442H13.8821C14.6368 10.153 16.0384 9.38297 17.5311 9.43806C21.4363 9.43806 22.155 12.007 22.155 15.3473L22.1537 22.1547ZM5.78475 8.05044C4.54965 8.05044 3.54901 7.04976 3.54901 5.81461C3.54901 4.57946 4.54965 3.57878 5.78475 3.57878C7.01986 3.57878 8.0205 4.57946 8.0205 5.81461C8.0205 7.04976 7.01986 8.05044 5.78475 8.05044ZM7.71044 22.1547H3.85394V9.74685H7.71044V22.1547ZM24.0743 0.00145629H1.918C0.871236 -0.0100752 0.0128123 0.829161 0 1.87596V24.1241C0.0128123 25.1721 0.871236 26.0114 1.918 25.9999H24.0743C25.1236 26.0127 25.9859 25.1734 26 24.1241V1.87468C25.9846 0.825317 25.1224 -0.0139191 24.0743 0.000174952"
      fill="white"
    />
  </svg>
);

export default LinkedinVariant1;
