import React from 'react';

const TwitterVariant1 = () => (
  <svg
    viewBox="0 -1 31 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.8264 5.97447C27.8445 6.23416 27.8445 6.49507 27.8445 6.75722C27.8445 14.7657 21.4455 24 9.74821 24V23.9951C6.293 24 2.90883 23.0572 0 21.2788C0.502458 21.3367 1.0075 21.365 1.51383 21.3662C4.37875 21.3687 7.161 20.453 9.41238 18.7669C6.69083 18.7176 4.30383 17.0266 3.47071 14.5577C4.42396 14.7325 5.40562 14.6968 6.34208 14.4543C3.37383 13.8833 1.24 11.3984 1.24 8.5135C1.24 8.48765 1.24 8.46181 1.24 8.43719C2.12479 8.90734 3.11421 9.16703 4.12688 9.19533C1.33171 7.4169 0.468875 3.87481 2.15708 1.10563C5.38625 4.89141 10.1512 7.19291 15.2649 7.43783C14.7521 5.33325 15.4535 3.12775 17.1042 1.64716C19.6656 -0.648185 23.6943 -0.530033 26.1033 1.91054C27.528 1.64347 28.8933 1.14501 30.1423 0.439796C29.667 1.84285 28.6737 3.03421 27.3459 3.79235C28.6078 3.64836 29.8388 3.32713 31 2.83729C30.1462 4.0545 29.0715 5.11664 27.8264 5.97447Z"
      fill="white"
    />
  </svg>
);

export default TwitterVariant1;
