import React from 'react';

const MyLogoVariant2 = () => (
  <svg
    viewBox="0 0 125 130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M119.424 55.0583C121.486 54.2306 123.608 56.1114 123.034 58.2579L104.984 125.782C104.467 127.72 102.091 128.434 100.59 127.104L53.7748 85.6164C52.274 84.2863 52.6979 81.8417 54.559 81.0947L119.424 55.0583Z"
      fill="#640DFB"
    />
    <path
      d="M44.2262 104.595C31.5744 104.595 21.398 101.743 13.6969 96.0383C5.99584 90.2356 1.4302 82.0726 0 71.5492L26.0737 63.878C26.6237 70.074 28.329 74.7456 31.1894 77.8928C34.1598 80.9416 38.2854 82.466 43.5661 82.466C49.0669 82.466 53.9076 80.6957 58.0881 77.1552C62.3787 73.5162 65.2392 68.6971 66.6694 62.6978L74.9203 27.4752C78.6905 11.3807 93.0451 0 109.575 0L93.4031 67.271C91.6428 74.844 88.3974 81.4334 83.6667 87.0393C79.0461 92.6452 73.3253 96.9726 66.5043 100.021C59.7934 103.07 52.3673 104.595 44.2262 104.595Z"
      fill="white"
    />
  </svg>
);

export default MyLogoVariant2;
