import React from 'react';

const GitHubVariant1 = () => (
  <svg
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.4994 0C6.93984 0 0 6.88476 0 15.3792C0 22.1743 4.44057 27.9389 10.599 29.9728C11.3739 30.115 11.6581 29.6396 11.6581 29.2333C11.6581 28.8668 11.6439 27.6557 11.6374 26.3702C7.32603 27.3007 6.41544 24.5555 6.41544 24.5555C5.71022 22.7779 4.69501 22.305 4.69501 22.305C3.28845 21.3502 4.80093 21.3707 4.80093 21.3707C6.35732 21.4796 7.1762 22.956 7.1762 22.956C8.55823 25.3065 10.8018 24.6272 11.6865 24.2338C11.826 23.2393 12.2277 22.5613 12.6707 22.1768C9.22857 21.7885 5.60947 20.4697 5.60947 14.5769C5.60947 12.898 6.21524 11.5254 7.2072 10.4489C7.04575 10.0606 6.5149 8.49701 7.35703 6.37853C7.35703 6.37853 8.65897 5.96585 11.6206 7.95489C12.8567 7.61399 14.1819 7.44354 15.4994 7.43713C16.8168 7.44354 18.1433 7.61399 19.3819 7.95489C22.3397 5.96457 23.6404 6.37853 23.6404 6.37853C24.4838 8.49701 23.953 10.0606 23.7928 10.4489C24.7861 11.5254 25.3879 12.898 25.3879 14.5769C25.3879 20.4838 21.7624 21.7846 18.3112 22.1653C18.8666 22.642 19.3626 23.5789 19.3626 25.013C19.3626 27.0712 19.3445 28.7271 19.3445 29.2333C19.3445 29.6421 19.6235 30.1227 20.4088 29.9715C26.5646 27.935 31 22.173 31 15.3805C30.9987 6.88604 24.0589 0 15.4994 0Z"
      fill="white"
    />
  </svg>
);

export default GitHubVariant1;
