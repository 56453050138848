import React from 'react';

const FacebookVariant1 = () => (
  <svg
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31 15.0912C31 22.6229 25.3244 28.8669 17.9154 30V19.4827H21.5179L22.2038 15.1314H17.9154V12.3081C17.9154 11.1172 18.5147 9.95766 20.4342 9.95766H22.3833V6.25278C22.3833 6.25278 20.6137 5.9585 18.9229 5.9585C15.3915 5.9585 13.0846 8.04234 13.0846 11.8139V15.1302H9.15921V19.4815H13.0846V29.9987C5.67687 28.8644 0 22.6217 0 15.0912C0 6.75707 6.94012 0 15.5 0C24.0599 0 31 6.75582 31 15.0912Z"
      fill="white"
    />
  </svg>
);

export default FacebookVariant1;
