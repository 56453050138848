import React from 'react';

const MyLogoVariant1 = () => (
  <svg
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5 2.02073C22.6658 0.770296 25.3342 0.770296 27.5 2.02073L41.2846 9.97927C43.4504 11.2297 44.7846 13.5406 44.7846 16.0415V31.9585C44.7846 34.4594 43.4504 36.7703 41.2846 38.0207L27.5 45.9793C25.3342 47.2297 22.6658 47.2297 20.5 45.9793L6.71539 38.0207C4.54958 36.7703 3.21539 34.4594 3.21539 31.9585V16.0415C3.21539 13.5406 4.54958 11.2297 6.71539 9.97927L20.5 2.02073Z"
      fill="white"
    />
    <path
      d="M31.2614 22.7431C32.3826 22.293 33.5367 23.3158 33.2247 24.483L30.4649 34.8074C30.1833 35.8609 28.8913 36.2495 28.0752 35.5263L20.9172 29.1828C20.1011 28.4596 20.3317 27.1303 21.3437 26.7241L31.2614 22.7431Z"
      fill="#640DFB"
    />
    <path
      d="M17.8795 32C15.3394 32 13.2962 31.4274 11.75 30.2821C10.2038 29.1171 9.28715 27.4781 9 25.3653L14.2349 23.8251C14.3454 25.0691 14.6878 26.0071 15.262 26.6389C15.8584 27.2511 16.6867 27.5571 17.747 27.5571C18.8514 27.5571 19.8233 27.2017 20.6626 26.4908C21.5241 25.7602 22.0984 24.7927 22.3855 23.5882L24.0421 16.5163C24.7991 13.285 27.6811 11 31 11L27.753 24.5063C27.3996 26.0268 26.748 27.3498 25.7982 28.4753C24.8705 29.6008 23.7219 30.4697 22.3524 31.0818C21.005 31.6939 19.5141 32 17.8795 32Z"
      fill="black"
    />
  </svg>
);

export default MyLogoVariant1;
